










import VueRouter from 'vue-router';
import { Component, Vue } from 'vue-property-decorator';
import Button from '@/components/Button.vue';

@Component({
  components: {
    Button,
  },
})
export default class Home extends Vue {
  private handleClick() {
    this.$router.push({ name: 'polls' })
      .catch((failure) => {
        if (!VueRouter.isNavigationFailure(failure, VueRouter.NavigationFailureType.redirected)) {
          throw failure;
        }
      });
  }
}
