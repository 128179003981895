














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Button extends Vue {
  @Prop({ default: 'var(--app-color, #CB89FF)' })
  private color!: string;

  @Prop({ default: false, type: Boolean })
  private inverted!: boolean;

  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;
}
